import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FieldArray, useFormikContext, ArrayHelpers } from "formik";
import { IEntity, PaymentsTicketOfficeTypeEnum } from "../../../types";
import { useTranslation } from "react-i18next";
import InputText from "../../../components/inputs/inputText";
import { Trash } from "react-bootstrap-icons";
import InputPhone from "../../../components/inputs/inputPhone";
import RadioBoxTicketOrder from "../../../components/inputs/radioBoxTicketOrder";
import PreOrder from "./img/PreOrder";
import Management from "./img/Management";
import Generator from "./img/Generator";
import { useGetOrganizerServiceInfoQuery } from "../../../qraphql/event.hooks";

type PushFunction = ArrayHelpers["push"];

interface ITicketOfficeProps {
  isFreeTicketsQuantityExceed: boolean;
}

export default function TicketOffice({
  isFreeTicketsQuantityExceed,
}: ITicketOfficeProps) {
  const { t } = useTranslation();
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<IEntity>();
  const { data } = useGetOrganizerServiceInfoQuery();

  const handleAddContact = (push: PushFunction) => {
    push({
      email: "",
      phone: "",
    });
    setFieldTouched(
      `merchantContacts[${values.merchantContacts.length}].email`,
      true
    );
    setFieldTouched(
      `merchantContacts[${values.merchantContacts.length}].phone`,
      true
    );
  };

  const handleAddAddress = (push: PushFunction) => {
    push("");
    setFieldTouched(`ticketAddresses[${values.ticketAddresses.length}]`, true);
  };

  return (
    <>
      <div className="fs-5 fw-bold mb-3">{t("thirdStep.Ticket Order")}</div>
      <RadioBoxTicketOrder
        checked={
          values.ticketOfficeType ===
          PaymentsTicketOfficeTypeEnum.ShowModalTicketOfficeType
        }
        onChange={() =>
          setFieldValue(
            "ticketOfficeType",
            PaymentsTicketOfficeTypeEnum.ShowModalTicketOfficeType
          )
        }
        id="inline-radio-order"
        title={t("thirdStep.Full Organizer Management")}
        description={t(
          "thirdStep.The organizer independently manages tiket orders without the ability to download tickets or pre-orders"
        )}
        img={<Management />}
      />
      <RadioBoxTicketOrder
        checked={
          values.ticketOfficeType ===
          PaymentsTicketOfficeTypeEnum.PreOrderTicketOfficeType
        }
        onChange={() =>
          setFieldValue(
            "ticketOfficeType",
            PaymentsTicketOfficeTypeEnum.PreOrderTicketOfficeType
          )
        }
        id="inline-radio-order"
        title={t("thirdStep.Customer Pre-Order Tickets")}
        description={t(
          "thirdStep.Pre-orders are made via ME-Ticket with manual sending of tickets without the ability to download all or one by one"
        )}
        img={<PreOrder />}
      />
      <RadioBoxTicketOrder
        checked={
          values.ticketOfficeType ===
          PaymentsTicketOfficeTypeEnum.GenerateTicketTicketOfficeType
        }
        onChange={() =>
          setFieldValue(
            "ticketOfficeType",
            PaymentsTicketOfficeTypeEnum.GenerateTicketTicketOfficeType
          )
        }
        id="inline-radio-order"
        title={t("thirdStep.Ticket Generator")}
        description={t(
          "thirdStep.Ability to download tickets all or one by one in PDF format for sale without pre-orders"
        )}
        img={<Generator />}
        isProInfo={
          !data?.activeSubscription &&
          values.ticketOfficeType ===
            PaymentsTicketOfficeTypeEnum.GenerateTicketTicketOfficeType
        }
      />
      {isFreeTicketsQuantityExceed && (
        <div className="text-danger">
          {t(
            "thirdStep.Total tickets exceed 50 pcs, please change it in the previous step."
          )}
        </div>
      )}
      <div className="fs-5 fw-bold mb-3 mt-4">{t("thirdStep.whereBuy")}</div>
      <FieldArray name="ticketAddresses">
        {({ push, remove }) => (
          <>
            {values.ticketAddresses.map((_, index) => (
              <div className="d-flex gap-3" key={index}>
                <InputText
                  placeholder={t("firstStep.address")}
                  name={`ticketAddresses[${index}]`}
                  className="mb-3 w-100"
                />
                {index > 0 && (
                  <Button
                    variant="outline-secondary"
                    className="text-dark"
                    style={{ width: 58, height: 58 }}
                    onClick={() => remove(index)}
                  >
                    <Trash width={18} height={18} />
                  </Button>
                )}
              </div>
            ))}
            {values.ticketAddresses.length < 5 && (
              <Button
                variant="link"
                className="text-success"
                onClick={() => handleAddAddress(push)}
              >
                + {t("thirdStep.addAddress")}
              </Button>
            )}
          </>
        )}
      </FieldArray>
      <div className="fs-5 fw-bold mb-3 mt-4">
        {t("thirdStep.merchantContact")}
      </div>
      <FieldArray name="merchantContacts">
        {({ push, remove }) => (
          <>
            {values.merchantContacts.map((_, index) => (
              <Row key={index} className="g-3 mb-3">
                <Col lg={6}>
                  <InputText
                    name={`merchantContacts[${index}].email`}
                    placeholder={t("secondStep.email")}
                  />
                </Col>
                <Col lg={6} className="d-flex gap-3">
                  <InputPhone
                    country={values.countryCode}
                    name={`merchantContacts[${index}].phone`}
                    value={values.merchantContacts[index].phone}
                    onChange={(phone) =>
                      setFieldValue(`merchantContacts[${index}].phone`, phone)
                    }
                    onBlur={() =>
                      setFieldTouched(`merchantContacts[${index}].phone`, true)
                    }
                  />
                  {index > 0 && (
                    <Button
                      variant="outline-secondary"
                      className="text-dark"
                      style={{ width: 58, height: 58 }}
                      onClick={() => remove(index)}
                    >
                      <Trash width={18} height={18} />
                    </Button>
                  )}
                </Col>
              </Row>
            ))}

            {values.merchantContacts.length < 5 && (
              <Button
                variant="link"
                className="text-success"
                onClick={() => handleAddContact(push)}
              >
                + {t("thirdStep.addMerchant")}
              </Button>
            )}
          </>
        )}
      </FieldArray>
    </>
  );
}
