import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
interface IBankDetailsModalProps {
  handleClose: () => void;
  handleSave: () => void;
  loading: boolean;
}

export default function BankDetailsModal({
  handleClose,
  handleSave,
  loading,
}: IBankDetailsModalProps) {
  const { t } = useTranslation();

  return (
    <>
      <Modal show={true} onHide={handleClose} centered>
        <Modal.Header closeButton className="border-0">
          <Modal.Title>{t("thirdStep.bankDetails")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div className="text-center mb-5 px-3">
            {t("thirdStep.checkBankAccount")}
          </div>
          <Row className="g-3">
            <Col lg={6}>
              <Button
                variant="success"
                className="fw-bold py-3 px-5 w-100"
                onClick={handleClose}
              >
                {t("thirdStep.checkAgain")}
              </Button>
            </Col>
            <Col lg={6}>
              <Button
                variant="outline-success"
                className="fw-bold py-3 px-5 w-100"
                onClick={handleSave}
                disabled={loading}
              >
                {t("thirdStep.nextStep")}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
