import React from "react";
import { Col, Row } from "react-bootstrap";
import SelectInput from "../../../components/inputs/select";
import { useFormikContext } from "formik";
import { IEntity, SelectOption } from "../../../types";
import { useTranslation } from "react-i18next";
import InputDateTime from "../../../components/inputs/inputDateTime";
import { useTimezonesProxyQuery } from "../../../qraphql/event.hooks";
import { useReactiveVar } from "@apollo/client";
import { isTicketSoldOutForUpcomingRecurringEvents } from "../../../qraphql/eventState";

export default function EventTIme() {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext<IEntity>();
  const { data: timezonesData, loading: timezoneLoading } =
    useTimezonesProxyQuery();

  const isRecurringSoldOut = useReactiveVar(
    isTicketSoldOutForUpcomingRecurringEvents
  );

  const timezonesOptions = timezonesData?.timezonesProxy
    ? [...timezonesData.timezonesProxy]
        .sort((a, b) => parseInt(a.gmt, 10) - parseInt(b.gmt, 10))
        .map((item) => ({
          value: item.id.toString(),
          label: `(GMT${item.gmt}) ${item.name}`,
        }))
    : [];

  return (
    <Row className="g-3 gy-1 mb-3">
      <Col xs={12} md={4}>
        <InputDateTime
          placeholder={t("firstStep.startDate")}
          name="startDate"
          disabled={isRecurringSoldOut}
        />
      </Col>
      <Col xs={12} md={4}>
        <InputDateTime
          placeholder={t("firstStep.endDate")}
          name="endDate"
          disabled={isRecurringSoldOut}
        />
      </Col>
      <Col xs={12} md={4}>
        <SelectInput
          options={timezonesOptions}
          placeholder={t("firstStep.Select Time Zone")}
          label={t("firstStep.Time Zone")}
          name="timezoneId"
          className="mb-3"
          isLoading={timezoneLoading}
          value={timezonesOptions.find(
            (option) => option.value === values.timezoneId
          )}
          onChange={(selectedOption: SelectOption | null) => {
            setFieldValue(
              "timezoneId",
              selectedOption ? selectedOption.value : ""
            );
          }}
          isDisabled={isRecurringSoldOut}
        />
      </Col>
    </Row>
  );
}
