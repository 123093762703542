import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import RadioBoxWithImage from "../../../components/inputs/radioBoxWithImage";
import OfflineEventImg from "./img/OfflineEvent";
import OnlineEventImg from "./img/OnlineEvent";
import OnlineFields from "./OnlineFields";
import OfflineFields from "./OfflineFields";
import { InfoCircle } from "react-bootstrap-icons";
import { useFormikContext } from "formik";
import { IEntity } from "../../../types";
import { useTranslation } from "react-i18next";
import { useGetEventProxyLazyQuery } from "../../../qraphql/event.hooks";
import { useParams } from "react-router-dom";

export default function EventFormat() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { values, setFieldValue } = useFormikContext<IEntity>();
  const [getEvent, { data: eventData }] = useGetEventProxyLazyQuery();

  useEffect(() => {
    if (id) {
      getEvent({ variables: { eventId: Number(id) } });
    }
  }, [id]);

  const isEventApprovedOrInModeration =
    id &&
    ((!eventData?.eventProxy.catalogApprove &&
      eventData?.eventProxy.status === 0) ||
      (eventData?.eventProxy.catalogApprove &&
        eventData?.eventProxy.status === 1));

  return (
    <>
      <div className="fw-bold fs-4 mb-4 mt-5">{t("firstStep.eventFormat")}</div>
      <div className="bg-light-gray text-info p-3 rounded d-flex mb-3">
        <div>
          <InfoCircle className="pe-2 fs-4" height={26} width={26} />
        </div>
        {t("firstStep.eventFormatInfo")}
      </div>
      <Row className="gx-3 gy-2 mb-4">
        <Col xs={12} md={6}>
          <RadioBoxWithImage
            label={t("firstStep.offlineEvent")}
            name="eventFormat"
            id="inline-radio-offline"
            img={<OfflineEventImg />}
            checked={!values.isOnline}
            onChange={() => setFieldValue("isOnline", false)}
            disabled={values.isOnline && isEventApprovedOrInModeration}
          />
        </Col>
        <Col xs={12} md={6}>
          <RadioBoxWithImage
            label={t("firstStep.onlineEvent")}
            name="eventFormat"
            id="inline-radio-online"
            img={<OnlineEventImg />}
            checked={values.isOnline}
            onChange={() => setFieldValue("isOnline", true)}
            disabled={!values.isOnline && isEventApprovedOrInModeration}
          />
        </Col>
      </Row>
      {values.isOnline ? <OnlineFields /> : <OfflineFields />}
    </>
  );
}
