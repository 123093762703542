import React from "react";
const Generator = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={59} height={47} fill="none">
    <path
      fill="#16B054"
      d="M53.93 25.435h-2.538v-13.84c0-1.362-.914-2.466-2.04-2.466h-1.39c-1.126 0-2.04 1.104-2.04 2.466v13.84h-2.538c-1.851 0-2.852 2.623-1.649 4.324l5.273 7.455c.865 1.225 2.432 1.225 3.298 0l5.273-7.455c1.203-1.701.202-4.324-1.65-4.324ZM56.664 39.465H40.656A2.336 2.336 0 0 0 38.32 41.8v1.16a2.336 2.336 0 0 0 2.336 2.336h16.008A2.336 2.336 0 0 0 59 42.96V41.8a2.336 2.336 0 0 0-2.336-2.335Z"
    />
    <path
      fill="#fff"
      stroke="#3E4957"
      strokeWidth={0.5}
      d="M14.394 7.353a2.458 2.458 0 0 0-3.146-1.396h-.002l-.044.018-1.427.566-.156.062-.001.169A3.485 3.485 0 0 1 3.61 9.155l-.116-.122-.157.063-1.426.566a2.458 2.458 0 0 0-1.379 3.191l10.663 26.87a2.459 2.459 0 0 0 3.192 1.377l-.092-.232.092.232 1.466-.581.15-.06.007-.16a3.503 3.503 0 0 1 5.936-2.356l.116.112.15-.06 1.466-.582a2.458 2.458 0 0 0 1.378-3.191L14.394 7.353ZM3.673 15.82a.55.55 0 1 1-.406-1.024.55.55 0 0 1 .406 1.024Zm2.084-.828a.55.55 0 1 1-.406-1.024.55.55 0 1 1 .406 1.024Zm2.085-.827a.55.55 0 1 1-.406-1.024.55.55 0 1 1 .406 1.024Zm2.085-.827a.55.55 0 1 1 .308-.715.55.55 0 0 1-.308.715Zm1.37-1.136a.55.55 0 1 1 1.024-.406.55.55 0 1 1-1.024.406Zm2.8-.518a.55.55 0 1 1-.406-1.024.55.55 0 0 1 .406 1.024Z"
    />
    <path
      fill="#3E4957"
      d="m11.737 27.212-1.401.543a.86.86 0 0 1-1.11-.49l-.543-1.402a.86.86 0 0 1 .49-1.11l1.401-.543a.86.86 0 0 1 1.11.49l.543 1.402a.86.86 0 0 1-.49 1.11ZM9.42 25.391a.174.174 0 0 0-.1.225l.543 1.401c.035.09.136.134.226.1l1.4-.543a.174.174 0 0 0 .1-.225l-.543-1.4a.174.174 0 0 0-.225-.1l-1.4.542ZM7.384 24.064a.86.86 0 0 0-.49 1.11l.37.956a.342.342 0 1 0 .638-.247l-.247-.637a.517.517 0 0 1 .295-.668l.663-.257a.342.342 0 1 0-.247-.638l-.982.38ZM21.37 31.285a.86.86 0 0 0 .49-1.11l-.37-.956a.342.342 0 1 0-.638.247l.247.636a.517.517 0 0 1-.296.668l-.663.257a.342.342 0 1 0 .248.638l.981-.38ZM18.207 20.779a.86.86 0 0 0-1.111-.49l-.956.37a.342.342 0 1 0 .248.638l.636-.247a.517.517 0 0 1 .668.295l.257.663a.342.342 0 1 0 .638-.247l-.38-.982ZM10.544 34.569a.86.86 0 0 0 1.11.49l.956-.37a.342.342 0 1 0-.247-.638l-.636.247a.517.517 0 0 1-.668-.295l-.257-.663a.342.342 0 1 0-.638.247l.38.982Z"
    />
    <path
      fill="#3E4957"
      d="M10.457 26.359a.363.363 0 1 0 0-.726.363.363 0 0 0 0 .726ZM13.815 32.814l-1.401.542a.86.86 0 0 1-1.11-.49l-.543-1.401a.86.86 0 0 1 .49-1.11l1.401-.543a.86.86 0 0 1 1.11.49l.543 1.401a.86.86 0 0 1-.49 1.11Zm-2.316-1.822a.174.174 0 0 0-.1.226l.542 1.4c.035.09.136.135.226.1l1.4-.542a.174.174 0 0 0 .1-.226l-.542-1.4a.174.174 0 0 0-.226-.1l-1.4.543Z"
    />
    <path
      fill="#3E4957"
      d="M12.535 31.96a.363.363 0 1 0 0-.726.363.363 0 0 0 0 .726ZM17.244 25.08l-1.4.542a.86.86 0 0 1-1.11-.49l-.543-1.402a.86.86 0 0 1 .49-1.11l1.401-.543a.86.86 0 0 1 1.11.49l.543 1.402a.86.86 0 0 1-.49 1.11Zm-2.316-1.822a.174.174 0 0 0-.1.225l.543 1.401c.034.09.136.134.225.1l1.401-.543a.174.174 0 0 0 .1-.225l-.543-1.4a.174.174 0 0 0-.225-.1l-1.4.542Z"
    />
    <path
      fill="#3E4957"
      d="M15.964 24.226a.363.363 0 1 0 0-.726.363.363 0 0 0 0 .726ZM18.05 26.171a.363.363 0 1 0 0-.726.363.363 0 0 0 0 .726ZM17.183 30.144a.363.363 0 1 0 0-.726.363.363 0 0 0 0 .726ZM13.444 23.47l.733 1.896a.363.363 0 1 1-.676.262l-.734-1.896a.363.363 0 1 1 .677-.262ZM11.856 28.631l1.895-.735a.362.362 0 1 1 .263.676l-1.895.736a.362.362 0 1 1-.263-.677Z"
    />
    <path
      fill="#3E4957"
      d="m13.367 25.29.56 1.445a.363.363 0 1 1-.676.262l-.43-1.107a.362.362 0 0 1 .208-.47l.338-.13Z"
    />
    <path
      fill="#3E4957"
      d="m14.049 25.028.13.338a.363.363 0 0 1-.207.47l-.681.263a.363.363 0 1 1-.262-.677l1.02-.395ZM13.517 23.75l.132.339a.363.363 0 0 1-.208.469l-.681.264a.363.363 0 1 1-.262-.677l1.02-.395ZM12.962 28.954l-.338.133a.363.363 0 0 1-.47-.204l-.27-.68a.363.363 0 0 1 .675-.266l.402 1.017ZM11.2 28.498a.361.361 0 0 1-.204.471l-.343.136-.673.267-.02-.049-.114-.289a.363.363 0 0 1 .204-.47l.056-.022.623-.247a.363.363 0 0 1 .47.204Z"
    />
    <path
      fill="#3E4957"
      d="m10.524 28.765.13.339.13.344a.363.363 0 0 1-.677.258l-.147-.384-.132-.346a.363.363 0 0 1 .222-.414l.056-.022a.362.362 0 0 1 .418.225ZM15.124 29.61a.387.387 0 0 1-.01-.273.303.303 0 0 1 .17-.185l.302-.123.593-.244.02.049.117.287a.43.43 0 0 1 .019.057c.041.169-.032.34-.179.4l-.05.02-.548.226c-.164.067-.358-.029-.434-.214Z"
    />
    <path
      fill="#3E4957"
      d="m15.574 29.219-.133-.337-.134-.343a.363.363 0 1 1 .675-.266l.15.382.136.345a.362.362 0 0 1-.216.416l-.056.023a.362.362 0 0 1-.421-.22ZM14.871 31.27l-.133-.338-.135-.342a.363.363 0 0 1 .676-.266l.15.382.136.345a.363.363 0 0 1-.216.416l-.056.022a.362.362 0 0 1-.421-.22Z"
    />
    <path
      fill="#3E4957"
      d="m16.02 31.206-.338.133-.343.135a.362.362 0 1 1-.265-.675l.382-.15.345-.136c.17-.039.348.05.416.217l.023.056a.362.362 0 0 1-.22.42Z"
    />
    <path
      fill="#3E4957"
      d="m16.219 30.737.13.338.132.344a.362.362 0 1 1-.677.26l-.147-.384-.133-.345a.362.362 0 0 1 .22-.414l.056-.023a.362.362 0 0 1 .419.224Z"
    />
    <path
      fill="#3E4957"
      d="m16.956 31.626-.338.13-.344.132a.362.362 0 1 1-.26-.677l.383-.148.346-.133a.363.363 0 0 1 .414.22l.023.056a.362.362 0 0 1-.224.42ZM18.925 30.837l-.338.13-.344.132a.362.362 0 1 1-.26-.677l.383-.148.346-.133a.363.363 0 0 1 .414.22l.022.056a.362.362 0 0 1-.223.42Z"
    />
    <path
      fill="#3E4957"
      d="m19.863 30.473-.339.13-.344.133a.362.362 0 1 1-.26-.678l.384-.147.345-.133a.363.363 0 0 1 .414.22l.023.056a.362.362 0 0 1-.224.419Z"
    />
    <path
      fill="#3E4957"
      d="m19.44 30.637-.337.13-.345.133a.362.362 0 1 1-.26-.677l.384-.148.345-.133a.362.362 0 0 1 .414.22l.023.056a.362.362 0 0 1-.224.419Z"
    />
    <path
      fill="#3E4957"
      d="m19.203 29.556.131.338.134.343a.362.362 0 1 1-.676.263l-.15-.384-.133-.345a.362.362 0 0 1 .218-.415l.056-.022a.362.362 0 0 1 .42.222ZM16.685 26.368l.069.18a.522.522 0 0 1-.319.314l-.294.114-.15.058-.452.175c-.246.095-.503.021-.575-.166a.325.325 0 0 1 .038-.29.56.56 0 0 1 .277-.221l.504-.196.385-.15.069-.026a.562.562 0 0 1 .177-.018c.119.022.224.104.27.226Z"
    />
    <path
      fill="#3E4957"
      d="m16.946 27.049.069.18.06.158.133.344a.362.362 0 1 1-.677.26l-.13-.335-.018-.048-.133-.346a.363.363 0 0 1 .22-.414l.056-.022a.361.361 0 0 1 .42.223Z"
    />
    <path
      fill="#3E4957"
      d="m16.68 26.369.07.18.06.159.132.344a.362.362 0 1 1-.677.26l-.129-.335-.019-.049-.132-.346a.363.363 0 0 1 .22-.414l.056-.022a.362.362 0 0 1 .42.223Z"
    />
    <path
      fill="#3E4957"
      d="m16.49 26.81.18-.069.158-.061.344-.132a.363.363 0 1 1 .26.677l-.334.129-.049.019-.345.133a.363.363 0 0 1-.415-.22l-.022-.056a.364.364 0 0 1 .223-.42ZM14.787 26.72l.18-.069.158-.06.344-.133a.363.363 0 1 1 .26.677l-.334.13-.049.018-.345.133a.363.363 0 0 1-.415-.22l-.022-.056a.363.363 0 0 1 .223-.42ZM17.873 28.142l.18-.069.158-.061.344-.132a.363.363 0 1 1 .26.677l-.334.129-.05.019-.345.133a.363.363 0 0 1-.414-.22l-.022-.056a.361.361 0 0 1 .223-.42Z"
    />
    <path
      fill="#3E4957"
      d="m18.13 28.04.18-.068.159-.062.343-.132a.363.363 0 1 1 .261.677l-.335.13-.048.018-.346.133a.363.363 0 0 1-.414-.22l-.022-.055a.361.361 0 0 1 .223-.42Z"
    />
    <path
      fill="#3E4957"
      d="m18.5 27.984-.07-.179-.061-.159-.134-.343a.363.363 0 1 1 .677-.262l.13.334.019.049.134.345a.362.362 0 0 1-.22.415l-.055.022a.362.362 0 0 1-.42-.222Z"
    />
    <path
      fill="#3E4957"
      d="m18.6 28.242-.069-.18-.061-.158-.133-.343a.363.363 0 1 1 .677-.262l.13.334.019.049.133.345a.362.362 0 0 1-.219.415l-.056.022a.362.362 0 0 1-.42-.222ZM13.586 27.961l.338-.131a.363.363 0 0 1 .47.206l.265.681a.363.363 0 1 1-.676.263l-.397-1.019ZM4.594 19.782l1.902-.755.187.471-1.902.755-.187-.47Zm.79-.068.49-.194.945 2.382-.49.194-.944-2.382ZM8.279 21.32l-.507.202-1.03-2.595.507-.2 1.03 2.594ZM9.162 20.873a.813.813 0 0 1-.452-.203 1.223 1.223 0 0 1-.318-.48l-.283-.711a1.23 1.23 0 0 1-.097-.568.82.82 0 0 1 .19-.458c.11-.132.263-.237.46-.316a.963.963 0 0 1 .48-.068.93.93 0 0 1 .44.18c.137.1.259.239.366.415l-.507.2a.708.708 0 0 0-.175-.184.455.455 0 0 0-.2-.082c-.07-.01-.14 0-.21.028a.492.492 0 0 0-.21.149.392.392 0 0 0-.083.222.646.646 0 0 0 .052.28l.283.712c.04.1.091.18.154.238a.393.393 0 0 0 .211.105c.08.012.165 0 .257-.036a.42.42 0 0 0 .263-.32.687.687 0 0 0-.002-.255l.507-.2c.042.201.047.386.016.553a.931.931 0 0 1-.196.432.957.957 0 0 1-.394.278 1.136 1.136 0 0 1-.552.088v.001ZM10.016 17.633l.489-.194 1.03 2.594-.49.194-1.03-2.594Zm.921 1.464.413-1.994.615-.244-.713 2.849-.315-.61v-.001Zm.313-.471.316-.433 1.538 1.217-.58.23-1.274-1.015ZM12.39 16.686l.49-.194 1.03 2.594-.49.194-1.03-2.594Zm.177-.07 1.561-.62.187.471-1.56.62-.188-.471Zm.425 1.07 1.365-.541.187.47-1.365.542-.187-.47Zm.418 1.053 1.56-.62.188.471-1.561.62-.187-.471ZM14.29 15.935l1.9-.755.188.47-1.902.756-.187-.471Zm.79-.068.489-.194.945 2.381-.489.194-.945-2.381Z"
    />
    <path
      fill="#fff"
      stroke="#3E4957"
      strokeWidth={0.5}
      d="m35.83 3.103-1.974-.317a4.865 4.865 0 0 1-8.908-1.432l-1.973-.317a2.877 2.877 0 0 0-3.297 2.384L13.7 40.599a2.878 2.878 0 0 0 2.384 3.297l2.028.327a4.89 4.89 0 0 1 8.798 1.414l2.029.326a2.877 2.877 0 0 0 3.297-2.384l5.977-37.178a2.876 2.876 0 0 0-2.384-3.297Zm-14.989 6a1.043 1.043 0 1 1 .331-2.06 1.043 1.043 0 0 1-.331 2.06Zm2.885.463a1.043 1.043 0 1 1 .331-2.06 1.043 1.043 0 0 1-.331 2.06Zm2.884.464a1.043 1.043 0 1 1 .332-2.06 1.043 1.043 0 0 1-.332 2.06Zm2.885.463a1.043 1.043 0 1 1 .332-2.06 1.043 1.043 0 0 1-.332 2.06Zm2.885.464a1.043 1.043 0 1 1 .331-2.06 1.043 1.043 0 0 1-.331 2.06Zm2.885.464a1.043 1.043 0 1 1 .331-2.06 1.043 1.043 0 0 1-.331 2.06Z"
    />
    <path
      fill="#3E4957"
      d="m22.322 26.91-1.93-.326a1.12 1.12 0 0 1-.915-1.29l.326-1.929a1.12 1.12 0 0 1 1.29-.916l1.929.327a1.12 1.12 0 0 1 .916 1.29l-.327 1.929a1.12 1.12 0 0 1-1.289.916Zm-1.378-3.582a.227.227 0 0 0-.262.186l-.326 1.93a.227.227 0 0 0 .185.261l1.93.327c.123.02.24-.063.262-.186l.326-1.93a.227.227 0 0 0-.186-.261l-1.93-.327ZM19.55 20.485a1.12 1.12 0 0 0-1.29.915l-.222 1.317a.446.446 0 0 0 .878.148l.149-.877a.672.672 0 0 1 .775-.55l.913.155a.445.445 0 1 0 .149-.88l-1.353-.228ZM30.388 37.889a1.12 1.12 0 0 0 1.289-.916l.223-1.316a.446.446 0 0 0-.88-.149l-.148.877a.672.672 0 0 1-.775.55l-.913-.154a.446.446 0 0 0-.149.879l1.353.228ZM33.846 24.022a1.12 1.12 0 0 0-.916-1.29l-1.316-.222a.446.446 0 1 0-.149.879l.877.149c.366.062.613.409.55.775l-.154.913a.446.446 0 1 0 .879.148l.228-1.352ZM16.086 34.352a1.12 1.12 0 0 0 .916 1.29l1.316.222a.446.446 0 1 0 .149-.878l-.877-.149a.672.672 0 0 1-.55-.775l.154-.913a.446.446 0 1 0-.879-.149l-.229 1.353Z"
    />
    <path
      fill="#3E4957"
      d="M21.7 25.168a.473.473 0 1 0 0-.945.473.473 0 0 0 0 .945ZM20.908 34.563l-1.93-.327a1.12 1.12 0 0 1-.915-1.289l.326-1.93a1.12 1.12 0 0 1 1.29-.915l1.929.326a1.12 1.12 0 0 1 .916 1.29l-.327 1.929a1.12 1.12 0 0 1-1.29.916ZM19.53 30.98a.227.227 0 0 0-.262.186l-.327 1.93a.227.227 0 0 0 .186.262l1.93.326c.123.02.24-.062.261-.186l.327-1.93a.227.227 0 0 0-.186-.26l-1.93-.328Z"
    />
    <path
      fill="#3E4957"
      d="M20.293 32.82a.473.473 0 1 0 0-.945.473.473 0 0 0 0 .945ZM29.908 28.196l-1.93-.327a1.12 1.12 0 0 1-.915-1.29l.326-1.929a1.12 1.12 0 0 1 1.29-.915l1.929.326a1.12 1.12 0 0 1 .916 1.29l-.327 1.929a1.12 1.12 0 0 1-1.29.916Zm-1.378-3.583a.227.227 0 0 0-.262.186l-.327 1.93a.227.227 0 0 0 .186.261l1.93.327c.123.02.24-.063.261-.186l.327-1.93a.227.227 0 0 0-.186-.261l-1.93-.327Z"
    />
    <path
      fill="#3E4957"
      d="M29.293 26.453a.473.473 0 1 0 0-.945.473.473 0 0 0 0 .945ZM30.324 30.02a.473.473 0 1 0 0-.946.473.473 0 0 0 0 .945ZM26.7 33.887a.473.473 0 1 0 0-.946.473.473 0 0 0 0 .946ZM26.732 23.86l-.442 2.61a.472.472 0 1 1-.932-.157l.442-2.61a.473.473 0 0 1 .932.157ZM21.512 28.58l2.611.44a.472.472 0 1 1-.157.933l-2.61-.44a.473.473 0 1 1 .156-.932ZM25.424 25.844l-.337 1.991a.473.473 0 1 1-.932-.158l.258-1.526a.472.472 0 0 1 .545-.386l.466.078Z"
    />
    <path
      fill="#3E4957"
      d="m26.362 26.002-.078.466a.472.472 0 0 1-.545.387l-.94-.159a.473.473 0 0 1 .159-.931l1.404.238ZM26.62 24.22l-.079.467a.473.473 0 0 1-.545.387l-.938-.159a.472.472 0 0 1 .157-.932l1.405.238ZM22.535 29.677l-.466-.075a.473.473 0 0 1-.391-.543l.153-.94a.473.473 0 1 1 .933.152l-.229 1.406ZM20.87 27.993a.47.47 0 0 1-.542.391l-.474-.076-.932-.151.01-.067.065-.4a.472.472 0 0 1 .542-.39l.078.012.862.14a.473.473 0 0 1 .391.541Z"
    />
    <path
      fill="#3E4957"
      d="m19.93 27.843-.081.465-.083.472a.472.472 0 1 1-.93-.163l.092-.527.083-.476a.472.472 0 0 1 .523-.315l.078.013c.22.072.36.296.318.53ZM24.514 31.855a.504.504 0 0 1 .17-.312.394.394 0 0 1 .313-.094l.42.063.827.124-.01.067-.06.4a.559.559 0 0 1-.017.076c-.067.217-.262.36-.467.33l-.069-.01-.764-.115c-.229-.034-.382-.27-.343-.529Z"
    />
    <path
      fill="#3E4957"
      d="m25.28 31.715.076-.466.077-.474a.472.472 0 1 1 .933.153l-.087.528-.077.476a.473.473 0 0 1-.52.321l-.077-.011a.472.472 0 0 1-.325-.527ZM23.123 33.535l.077-.466.077-.474a.472.472 0 1 1 .933.153l-.087.529-.078.475a.472.472 0 0 1-.519.322l-.078-.012a.472.472 0 0 1-.325-.527Z"
    />
    <path
      fill="#3E4957"
      d="m24.453 34.234-.467-.076-.473-.078a.472.472 0 0 1 .153-.933l.529.087.476.078c.216.07.356.288.32.52l-.011.078a.473.473 0 0 1-.527.324Z"
    />
    <path
      fill="#3E4957"
      d="m24.99 33.84-.08.466-.081.473a.473.473 0 0 1-.931-.16l.09-.528.082-.475a.472.472 0 0 1 .523-.317l.077.012c.22.071.361.295.32.53Z"
    />
    <path
      fill="#3E4957"
      d="m25.221 35.33-.465-.08-.473-.081a.472.472 0 1 1 .16-.932l.528.09.475.083c.216.071.354.29.317.522l-.012.077a.472.472 0 0 1-.53.32v.001ZM27.948 35.756l-.465-.08-.473-.081a.472.472 0 1 1 .16-.932l.528.09.475.082c.215.072.354.291.317.522l-.012.078a.472.472 0 0 1-.53.32Z"
    />
    <path
      fill="#3E4957"
      d="m29.237 35.97-.465-.08-.473-.08a.472.472 0 1 1 .16-.932l.528.09.475.082c.215.072.354.29.317.522l-.012.078a.472.472 0 0 1-.53.32Z"
    />
    <path
      fill="#3E4957"
      d="m28.659 35.873-.465-.08-.473-.081a.472.472 0 1 1 .16-.932l.528.09.475.083c.215.071.354.29.317.522l-.012.077a.472.472 0 0 1-.53.32v.001Z"
    />
    <path
      fill="#3E4957"
      d="m29.112 34.507-.078.465-.08.474a.472.472 0 1 1-.932-.157l.089-.528.08-.475a.472.472 0 0 1 .52-.32l.079.013c.22.07.361.293.322.528ZM28.42 29.261l-.042.247c-.13.119-.342.177-.566.139l-.406-.068-.206-.035-.623-.105c-.338-.057-.577-.312-.533-.57a.425.425 0 0 1 .236-.298.732.732 0 0 1 .457-.063l.695.117.53.089.095.016a.731.731 0 0 1 .21.098.47.47 0 0 1 .153.434ZM28.264 30.2l-.042.247-.038.218-.082.473a.472.472 0 1 1-.931-.16l.08-.46.01-.068.083-.475a.473.473 0 0 1 .522-.317l.078.012a.47.47 0 0 1 .32.53Z"
    />
    <path
      fill="#3E4957"
      d="m28.42 29.262-.042.247-.038.219-.081.473a.472.472 0 1 1-.932-.16l.08-.461.011-.067.082-.475a.473.473 0 0 1 .522-.317l.078.012a.47.47 0 0 1 .32.53Z"
    />
    <path
      fill="#3E4957"
      d="m27.906 29.629.247.042.219.037.473.081a.473.473 0 0 1-.16.932l-.46-.08-.067-.01-.476-.082a.473.473 0 0 1-.317-.522l.012-.078a.471.471 0 0 1 .53-.321ZM26.063 28.394l.246.042.219.038.473.08a.472.472 0 1 1-.16.932l-.46-.079-.067-.011-.475-.082a.473.473 0 0 1-.318-.521l.013-.078a.47.47 0 0 1 .529-.321ZM28.57 32.039l.247.042.219.037.473.081a.473.473 0 0 1-.16.932l-.46-.079-.067-.012-.475-.081a.473.473 0 0 1-.318-.522l.012-.078a.47.47 0 0 1 .53-.32Z"
    />
    <path
      fill="#3E4957"
      d="m28.922 32.101.247.043.218.037.473.08a.473.473 0 1 1-.159.932l-.46-.078-.068-.012-.475-.081a.473.473 0 0 1-.317-.523l.012-.077a.471.471 0 0 1 .53-.321h-.001Z"
    />
    <path
      fill="#3E4957"
      d="m29.382 32.281.041-.246.038-.22.08-.472a.472.472 0 1 1 .932.158l-.078.46-.011.068-.081.475a.472.472 0 0 1-.522.319l-.077-.012a.471.471 0 0 1-.322-.529Z"
    />
    <path
      fill="#3E4957"
      d="m29.32 32.64.041-.246.037-.219.08-.473a.472.472 0 1 1 .932.158l-.078.461-.01.067-.081.476a.472.472 0 0 1-.522.318l-.078-.012a.471.471 0 0 1-.322-.529ZM23.89 28.98l.466.079a.472.472 0 0 1 .388.544l-.157.939a.472.472 0 1 1-.932-.156l.235-1.405ZM19.276 13.828l2.632.423-.105.652-2.631-.423.104-.652Zm.93.451.677.109-.53 3.295-.676-.108.53-3.296ZM22.38 18.01l-.7-.112.577-3.59.7.113-.576 3.59ZM23.661 18.09a1.058 1.058 0 0 1-.37-.528 1.6 1.6 0 0 1-.038-.749l.159-.985c.045-.283.135-.516.27-.7.133-.183.306-.312.517-.386a1.47 1.47 0 0 1 .727-.046c.226.037.42.118.581.243.162.126.287.29.373.495.086.203.13.44.133.708l-.701-.112a.916.916 0 0 0-.074-.324.6.6 0 0 0-.169-.225.531.531 0 0 0-.252-.109.642.642 0 0 0-.336.026.51.51 0 0 0-.24.195.85.85 0 0 0-.128.348l-.159.984a.84.84 0 0 0 .013.37.51.51 0 0 0 .168.258c.08.066.184.11.31.13a.548.548 0 0 0 .509-.182.896.896 0 0 0 .167-.287l.702.113a1.881 1.881 0 0 1-.351.631 1.21 1.21 0 0 1-.508.353 1.248 1.248 0 0 1-.627.048 1.48 1.48 0 0 1-.676-.27v.002ZM26.772 15.035l.677.109-.577 3.59-.677-.11.577-3.589Zm.056 2.253 1.79-1.956.852.137-2.697 2.711.055-.892Zm.664-.319.642-.274.91 2.388-.802-.13-.75-1.984ZM30.07 15.563l.675.108-.576 3.59-.677-.109.577-3.59Zm.244.039 2.16.347-.105.652-2.16-.348.105-.651Zm-.238 1.48 1.888.305-.104.651-1.889-.303.105-.652Zm-.235 1.457 2.16.348-.104.651-2.16-.347.104-.652ZM32.69 15.984l2.632.424-.104.651-2.632-.423.105-.652Zm.93.451.677.109-.53 3.296-.676-.109.53-3.296Z"
    />
  </svg>
);
export default Generator;
