import classNames from "classnames";
import React from "react";
import { Form, FormCheckProps } from "react-bootstrap";
import { useField } from "formik";
import { InfoCircle } from "react-bootstrap-icons";
import useGetUrlLocale from "../../../hooks/useGetUrlLocale";
import { useTranslation } from "react-i18next";

interface IRadioBoxWithImageProps extends FormCheckProps {
  img: React.ReactNode;
  title: string;
  description: string;
  isProInfo?: boolean;
}

export default function RadioBoxTicketOrder({
  img,
  title,
  description,
  isProInfo,
  ...props
}: IRadioBoxWithImageProps) {
  const [field] = useField(props.name);
  const urlLocale = useGetUrlLocale();
  const { t } = useTranslation();

  return (
    <Form.Label
      className={classNames(
        "d-flex justify-content-between align-items-center w-100 gap-2 p-3 rounded-3 border border-1 mb-3",
        {
          "bg-success-light": props.checked,
          "border-success": props.checked,
        }
      )}
    >
      <div>
        <div className="d-flex gap-2">
          <Form.Check type="radio" {...field} {...props} />
          <div>
            <div>{title}</div>
            <div className="text-info fs-7">{description}</div>
          </div>
        </div>
        {isProInfo && (
          <div className="d-flex fs-7 text-success align-items-center mt-3">
            <div>
              <InfoCircle className="pe-2 fs-4" height={26} width={26} />
            </div>
            <span>
              {t(
                "thirdStep.Free generate and download up to 50 tickets; additional tickets available with a"
              )}{" "}
              <a
                className="text-success fw-bold"
                href={`${process.env.REACT_APP_TICKET_URL}${urlLocale}/for-organizers/payment-subscription`}
              >
                {t("thirdStep.Pro Plan")}
              </a>
            </span>
          </div>
        )}
      </div>
      <div style={{ minWidth: 58 }}>{img}</div>
    </Form.Label>
  );
}
